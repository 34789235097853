import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MyUnityGame from './unity/unityGame';

function LandingPage({showUnity, setShowUnity}) {
  const navigate = useNavigate();

  useEffect(() => {
    // Function to check screen width and redirect if on mobile
    const checkScreenWidth = () => {
      if (window.innerWidth < 768) {
        navigate('/home'); // Redirect to home if on a mobile screen
      }
    };

    // Initial check
    checkScreenWidth();

    // Add event listener to listen for window resize events
    window.addEventListener('resize', checkScreenWidth);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, [navigate]);

  return (
    <Box sx={{ textAlign: 'center' }}>
      <MyUnityGame showUnity={showUnity} setShowUnity={setShowUnity}/>
    </Box>
  );
}

export default LandingPage;
