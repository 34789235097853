import React, { memo, useEffect, useState } from "react";
import { Box, IconButton, keyframes, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Define the ripple animation
const ripple = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`;
const fade = keyframes`
  0% { opacity: 0; }
  20% { opacity: 1; }
  33.33% { opacity: 1; }
  53.33% { opacity: 0; }
  100% { opacity: 0; }
`;

const ProductCarousel = ({carouselItems}) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [rippleIndex, setRippleIndex] = useState(0);

  useEffect(() => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        loadImg.onload = () => resolve(image);
        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(carouselItems.map(item => loadImage(item.image)))
      .then(() => setImagesLoaded(true))
      .catch(err => console.error("Failed to load images", err));
  }, [carouselItems]);

  // Add effect for ripple animation
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRippleIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [carouselItems.length]);

  if (!imagesLoaded) {
    return <Box>Loading images...</Box>;
  }

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? carouselItems.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === carouselItems.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100vh' }}>
      <Carousel
        autoPlay={false}
        animation="fade"
        navButtonsAlwaysInvisible={true}
        interval={5000}
        indicators={false}
        index={currentIndex}
        onChange={(index) => setCurrentIndex(index)}
        sx={{
          width: '100%',
          height: '100vh',
          boxShadow: "0 0 0 10px black"
        }}
      >
        {carouselItems.map((item, index) => (
          <Box
            key={index}
            sx={{ 
              height: '100vh', 
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  height: '100%', 
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  zIndex: 1,
                },
              }}
            >
              <img 
                src={item.image} 
                alt={item.caption}
                style={{ 
                  width: '100%', 
                  height: '100%', 
                  objectFit: 'cover',
                  filter:'blur(5px)',
                }}
              />
            </Box>
            <Box sx={{ position: 'relative', zIndex: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Typography 
                variant="h3" 
                sx={{ 
                  color: '#52D5F2', 
                  textAlign: 'center', 
                  padding: {xs:'5px',sm:'20px'},
                  fontWeight:'medium',
                  fontSize:{xs:'36px',sm:'42px', md:'48px'},
                  width:"100%",
                  
                }}
              >
                {item.title}
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: {xs:'column', sm:'row'},
                flex: 1,
                padding: {xs:'10px', sm:'50px'},
                alignItems: 'center'
              }}>
                <Box sx={{ 
                  width: {xs:'80%',sm:'35%'},
                  aspectRatio: '1 / 1',
                  position: 'relative',
                  borderRadius: '20px',
                  boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
                  margin: '0 auto'
                }}>
                  {item.subimages.map((subimage, subIndex) => (
                    <Box
                      key={subIndex}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        animation: `${fade} ${item.subimages.length * 3}s infinite`,
                        animationDelay: `${subIndex * 3}s`,
                      }}
                    >
                      <img 
                        src={subimage} 
                        alt={`Subimage ${subIndex + 1}`}
                        style={{ 
                          width: '100%', 
                          height: '100%', 
                          objectFit: 'cover',
                          borderRadius: '20px'
                        }}
                      />
                    </Box>
                  ))}
                </Box>
                <Box sx={{ 
                  width: {xs:'100%', sm:'50%'},
                  paddingLeft: {xs:'0px', sm:'20px'},
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems:'center',
                  textAlign:{xs:'center',sm:'left'},
                  marginTop:{xs:'20px', sm:'0px'}
                }}>
                  <Typography variant="h4" sx={{ color: '#52D5F2', marginBottom: '10px', width:'100%', fontSize:{xs:'24px',sm:'28px'}}}>
                    {item.subtitle?item.subtitle:''}
                  </Typography>
                  <Typography variant="h5" sx={{ color: '#fff', marginBottom: '10px',width:'100%', fontSize:{xs:'15px',sm:'20px'} }}>
                    <i>{item.caption?item.caption:''}</i>
                  </Typography>
                  <Typography variant="h6" sx={{ color: '#fff',width:'100%', fontSize:{xs:'12px',sm:'20px'}}}>
                    {item.text}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Carousel>
      <Box 
        sx={{ 
          position: 'absolute', 
          bottom: '20px',
          left: '50%', 
          transform: 'translateX(-50%)',
          display: 'flex',
          alignItems: 'center',
          zIndex: 3
        }}
      >
        <IconButton 
          onClick={handlePrevious} 
          sx={{ 
            color: '#fff',
            width: {xs:'25px',sm:'50px'},
            height: {xs:'25px',sm:'50px'},
            marginRight: '20px'
          }}
        >
          <ArrowBackIosIcon sx={{ fontSize: {xs:'15px',sm:'30px'}}} />
        </IconButton>
        {carouselItems.map((_, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <Box
                sx={{
                  width: {xs:'15px',sm:'30px'},
                  height: '2px',
                  backgroundColor: index <= currentIndex ? '#52D5F2' : 'rgba(255, 255, 255, 0.5)',
                }}
              />
            )}
            <Box
              onClick={() => setCurrentIndex(index)}
              sx={{
                width: {xs:'10px',sm:'20px'},
                height: {xs:'10px',sm:'20px'},
                borderRadius: '50%',
                backgroundColor: index <= currentIndex ? '#52D5F2' : 'rgba(255, 255, 255, 0.5)',
                margin: '0 5px',
                cursor: 'pointer',
                animation: rippleIndex === index ? `${ripple} 2s infinite` : 'none',
              }}
            />
          </React.Fragment>
        ))}
        <IconButton 
          onClick={handleNext} 
          sx={{ 
            color: '#fff',
            width: {xs:'25px',sm:'50px'},
            height: {xs:'25px',sm:'50px'},
            marginLeft: '20px'
          }}
        >
          <ArrowForwardIosIcon sx={{ fontSize: {xs:'15px',sm:'30px'} }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default memo(ProductCarousel);
