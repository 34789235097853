import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import image1 from '../../images/image1.png';
import Heyteach from "../../images/heyteach.png";
import Platforms from "../../images/platforms.png";
import Rated from "../../images/rated.png";
import Qr from "../../images/qr.png";

export default function DownloadComponent() {

  const navigate = useNavigate();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        setMousePosition({
          x: event.clientX - rect.left,
          y: event.clientY - rect.top,
        });
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      if (container) {
        container.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, []);

  return (
    <Box
      sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        height: '100vh'
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          borderRadius: '16px',
          boxShadow: 5,
          overflow: 'hidden',
          width: '95%',
          height: '100%',
          position: 'relative',
        }}
      >
        <img
          src={image1}
          alt="Description"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        {/* Blur Overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            backdropFilter: 'blur(5px)',
            zIndex: 1,
            maskImage: `radial-gradient(circle 150px at ${mousePosition.x}px ${mousePosition.y}px, transparent 0%, black 100%)`,
            WebkitMaskImage: `radial-gradient(circle 150px at ${mousePosition.x}px ${mousePosition.y}px, transparent 0%, black 100%)`,
          }}
        />

        {/* Back Button */}
        <Tooltip title="Go back to 3D tour" arrow>
          <IconButton
            sx={{
              position: 'absolute',
              top: 16,
              left: 16,
              zIndex: 3,
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
              },
              opacity: { xs: '0', sm:"100" },
            }}
            onClick={() => navigate('/')}
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        {/* Typography inside the container */}
        <Typography
          variant="h1"
          align="center"
          sx={{
            position: 'absolute',
            top: {xs:'6%',sm:'20%'},
            color: 'white',
            fontWeight: 'bold',
            textShadow: '2px 2px 8px rgba(0,0,0,1), 0 0 10px rgba(255,255,255,0.3)',
            fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' },
            
            width: '100%',
            padding: '0 16px',
            zIndex: 2,
          }}
        >
          Future State University
        </Typography>
        <Box className="absolute inset-0 flex justify-center" sx={{
          top:{xs:'20%',sm:'35%', md:'40%'}
        }}>
          <Box
            className="absolute w-56 h-56 rounded-full border-4 border-sky-blue-dark"
            sx={{
              backgroundColor: 'rgba(0,0,10, 0.5)', // Semi-transparent blue fill
              zIndex: 2, // Ensure it's above the blur part
            }}
          />
          <Box
            className="relative w-48 h-48 flex justify-center items-center z-10"
          >
            <img
              src={Heyteach}
              alt="Heyteach Logo"
              className="absolute w-40 h-40 object-contain transition-all duration-500 ease-in-out"
            />
          </Box>
        </Box>

        <Typography
          variant="h6"
          align="center"
          sx={{
            position: 'absolute',
            top: {xs:'50%',sm:'75%'},
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#52D5F2',
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
            zIndex: 2,
            textShadow: "5"

          }}
        >
          Download
        </Typography>

        {/* Three images in horizontal line */}
        <Box
          sx={{
            position: 'absolute',
            top: {xs:'60%',sm:'72%'},
            display: 'flex',
            gap: {xs:0,sm:2},
            zIndex: 2,
            flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on small screens
            alignItems: 'center', // Center-align when stacked
            justifyContent: 'center', // Center-align items horizontally
            width:"100%",
          }}
        >
          <Box
            component="img"
            src={Qr}
            alt="QR"
            sx={{
              width: { xs: '100px', sm: '150px' },
              height: { xs: '100px', sm: '150px' },
              objectFit: 'contain',
            }}
          />
          <Box
            component="img"
            src={Platforms}
            alt="Platforms"
            sx={{
              width: { xs: '150px', sm: '250px' },
              height: { xs: '150px', sm: '250px' },
              objectFit: 'contain',
            }}
          />
          <Box
            component="img"
            src={Rated}
            alt="Rated"
            sx={{
              width: { xs: '100px', sm: '150px' },
              height: { xs: '100px', sm: '150px' },
              objectFit: 'contain',
            }}
          />

        </Box>

      </Box>
    </Box>
  )
}
