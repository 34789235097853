import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box, IconButton, useMediaQuery, Menu, MenuItem, Container, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DownloadIcon from '@mui/icons-material/Download';
import { useTheme } from '@mui/material/styles';
import Logo from "../images/logo.png";
import FSU from "../images/FSU.png";
import ProductMenu from './productComponents/ProductMenu';
import AboutMenu from './productComponents/AboutMenu';
import { CustomButton } from './assets/customButton';

function Navigation() {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [productAnchorEl, setProductAnchorEl] = useState(null);
  const [aboutAnchorEl, setAboutAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setProductAnchorEl(null);
    setAboutAnchorEl(null); 
  };

  const handleProductClick = (event) => {
    setAboutAnchorEl(null);
    if (productAnchorEl) {
      setProductAnchorEl(null);
    } else {
      setProductAnchorEl(event.currentTarget);
    }
  };

  const handleAboutClick = (event) => {
    setProductAnchorEl(null);
    if (aboutAnchorEl) {
      setAboutAnchorEl(null);
    } else {
      setAboutAnchorEl(event.currentTarget);
    }
  };


  const menuItems = [
    { label: 'Home', path: '/home', key: '/home' },
    ...(isMobile 
      ? [
          { label: 'Candidate', path: 'product/candidate', key: 'product/candidate' },
          { label: 'Company', path: 'product/company', key: 'product/company' },
          { label: 'Mission', path: 'about/mission', key: 'about/mission' },
          { label: 'Team', path: 'about/team', key: 'about/team' },
          { label: 'Contact Us', path: 'about/contact', key: 'about/contact' },
        ]
      : [
          { label: 'Explore', path: '/', key: '/' },
          { label: 'Product', key: '/product', onClick: handleProductClick },
          { label: 'About', key: '/about', onClick: handleAboutClick },
        ]
    ),
  ];

  const isActive = (path) => {
    if (path === '/product') {
      return location.pathname.startsWith('/product');
    }
    if (path === '/about') {
      return location.pathname.startsWith('/about');
    }
    return location.pathname === path;
  };


  const handleDownload = () => {
    const fileUrl = 'https://fsuweb.s3.us-east-2.amazonaws.com/FSUGame.zip'; // Replace with your file URL
    window.location.href = fileUrl;
    // Optionally, close the menu if needed
    handleMenuClose();
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', position: 'sticky', top: 0, zIndex: 14 }}>
        <AppBar 
          position="static" 
          sx={{ 
            backgroundColor: theme.palette.secondary.dark, 
            color: "white",
            width: '90%',
            borderRadius: '16px',
          }}
        >
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center',    }}>
                  <img src={FSU} alt="FSU Logo" style={{ height: '70px' }} />
                </Box>
                {isMobile ? (
                  <>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleMenuOpen}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      {menuItems.map((item) => (
                        <MenuItem 
                          key={item.label} 
                          onClick={(event) => {
                            handleMenuClose();
                            if (item.onClick) item.onClick(event);
                          }} 
                          component={Link} 
                          to={item.path}
                          sx={{
                            color: isActive(item.key) ? theme.palette.primary.main : 'inherit',
                            fontWeight: isActive(item.key) ? 'bold' : 'medium',
                          }}
                        >
                          <Typography fontWeight="inherit" color="inherit">{item.label}</Typography>
                        </MenuItem>
                      ))}
                      {/* <MenuItem onClick={handleMenuClose} component={Link} to="/download">
                        <Button
                          variant="contained"
                          startIcon={<DownloadIcon />}
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            borderRadius: '20px',
                            ml: 2,
                            fontWeight: 'bold',
                          }}
                          onClick={handleDownload}
                        >
                          Download
                        </Button>
                      </MenuItem> */}
                    </Menu>
                  </>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap:"10px" }}>
                    {menuItems.map((item) => (
                      <Button 
                        key={item.label} 
                        color="inherit" 
                        component={Link} 
                        to={item.path}
                        onClick={(event) => {
                          handleMenuClose();
                          if (item.onClick) item.onClick(event);
                        }}
                        sx={{ 
                          fontWeight: isActive(item.key) ? 'bold' : 'medium',
                          color: isActive(item.key) ? theme.palette.primary.main : 'inherit',
                        }}
                      >
                        {item.label}
                      </Button>
                    ))}
                     {/* <CustomButton
                      onClick={handleDownload}
                    >
                      <DownloadIcon sx={{ mr  : 1, width:'25px' }} />
                      Download
                    </CustomButton> */}
                  </Box>
                )}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
      {productAnchorEl && (
        <ProductMenu 
          setProductAnchorEl={setProductAnchorEl}
          anchorEl={productAnchorEl} 
        />
      )}
      {aboutAnchorEl && (
        <AboutMenu 
          setAboutAnchorEl={setAboutAnchorEl}
          anchorEl={aboutAnchorEl} 
        />
      )}
    </>
  );
}

export default Navigation;
