import React from 'react'
import ProductCarousel from "./products/ProductCarousel"
import { Box } from '@mui/material'

import IH1 from '../images/IH1.png';
import IH2 from '../images/IH2.png';
import IH3 from '../images/IH3.png';
import IH4 from '../images/IH4.png';

import SN1 from '../images/SL1.png';
import SN2 from '../images/SL2.png';
import SN3 from '../images/SL3.png';
import SN4 from '../images/SL4.png';

import RU1 from '../images/RU1.png';
import RU2 from '../images/RU2.png';
import RU3 from '../images/RU3.png';
import RU4 from '../images/RU4.png';

import GA1 from '../images/GA1.png';
import GA2 from '../images/GA2.png';
import GA3 from '../images/GA3.png';
import GA4 from '../images/GA4.png';

import TD1 from '../images/3D1.png';
import TD2 from '../images/3D2.png';
import TD3 from '../images/3D3.png';
import TD4 from '../images/3D4.png';

import TM1 from '../images/TM1.png';
import TM2 from '../images/TM2.png';
import TM3 from '../images/TM3.png';
import TM4 from '../images/TM4.png';

import DE1 from '../images/DEI1.png';
import DE2 from '../images/DEI2.png';
import DE3 from '../images/DEI3.png';
import DE4 from '../images/DEI4.png';


const carouselItems = [
    { image: IH1, title: 'INFORMED HIRING', subtitle:'UNPARALLEDLED EMPLOYER', text:'candidate matching insights for better sourcing, interviewing, hiring, onboarding, and culture integration', subimages:[IH1,IH2,IH3,IH4] },
    { image: SN1, title: 'SOCIAL NETWORKING', subtitle:'Branded CAMPUS ISLAND', text:'Your candidates, employees and teams immerse themselves in the virtual company campus experience, where interactions and peer support are rewarded, creating a collaborative and dynamic learning and networking environment.',subimages:[SN1,SN2,SN3,SN4] },
    { image: RU1, title: 'RECRUIT U', subtitle:'AI SOUCING AGENT', text:"FSU's always-on generative AI instructor, career coach, and recruiter, is there to amplify your recruiting team and guide your candidates along an insight filled hiring journey. Elevate your recruiting team and candidate experience with RecruitU on your Custom Company Island.",subimages:[RU1,RU2,RU3,RU4] },
    { image: GA1, title: 'GAMIFIED ASSESSMENTS', subtitle:'GAMING CAMPUS', text:"FSU expands avenues for education and career growth by offering high-quality performance evaluations, 'Learn You' and 'JobU', within our immersive 3D university campus.",subimages:[GA1,GA2,GA3,GA4]},
    { image: TD1, title: '3D TRAINING', subtitle:'3D EXPERIENCE', text:"Hone employees’ interests, self-discover, socializing, and learning abilities. We connect your team with immersive versions of your training programs",subimages:[TD1,TD2,TD3,TD4]},
    { image: TM1, title: 'TEAM MEETINGS', subtitle:'COLLABORATE EFECTIVELY AND EFFICIENTLY', text:"Elevate collaboration with 3D Team Meetings, where your team gathers in a virtual space for dynamic discussions, brainstorming sessions, and seamless interaction.",subimages:[TM1,TM2,TM3,TM4]},
    { image: DE1, title: 'DE&I (DIVERSITY, EQUITY & INCLUSION', subtitle:'LOOK BEYOND CARBON COPY', text:"Step beyond hiring ‘carbon copy’ stereotypes and embrace diversity. Discover unique talent that aligns seamlessly with your organizational culture, fostering a workplace that thrives on diversity and inclusion.",subimages:[DE1,DE2,DE3,DE4]},
];

export default function ProductCompany() {
  return (
    <Box
      sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          borderRadius: '16px',
          boxShadow: 5,
          overflow: 'hidden',
          width: '95%',
          maxWidth: '100%',
          height: '100vh',
          position: 'relative',
        }}
      >
        <ProductCarousel carouselItems={carouselItems}/>
    </Box>
    </Box>
  )
}
