import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import image2 from '../../images/image2.png';
import Timeline from './TimelineComponent';

import LearnUImage from '../../images/howItWorks/LearnU.png';
import GetSocialImage from '../../images/howItWorks/GetSocial.png';
import AIMentorshipImage from '../../images/howItWorks/AIMentorship.png';
import ApplyJobsImage from '../../images/howItWorks/Jobs.png';
import InviteFriendsImage from '../../images/howItWorks/InviteFriends.png';
import TeachLearnImage from '../../images/howItWorks/TeachLearn.png';



const CandidateJourney = () => {
  const [activeStep, setActiveStep] = useState(1);
  const totalSteps = 4;

  const handleStepClick = (step) => {
    setActiveStep(step);
  };

  const timelineContent = [
    {
      title: "Professional Social Networking for the Next Generation",
      buttonText: "Build Your Network",
      position: 'above' // Use 'above' or 'below' to position cards relative to the timeline
    },
    {
      title: "Join our Multiplayer AI Classrooms",
      buttonText: "Join a Class",
      position: 'below'
    },
    {
      title: "Dive into the Adventure at FSU",
      buttonText: "Join the Adventure",
      position: 'above'
    },
    {
      title: "Your Personalized AI Career Roadmap",
      buttonText: "Explore Career Paths",
      position: 'below'
    }
  ];

  const howItWorksContent = [
    {
      title: "LearnU",
      text: "Engage socially and assess yourself in FSU's LearnU and JobU to connect with companies that align seamlessly with your profile. Leverage your results to apply for jobs, showcasing your true capabilities beyond a traditional resume.",
      Image: LearnUImage,
    },
    {
      title: "Get Social",
      text: "Immerse yourself in a virtual university networking experience where your interactions and assistance to peers in the community are rewarded.",
      Image: GetSocialImage,
    },
    {
      title: "AI Mentorship",
      text: "Interact with the FSU avatar guides to receive personalized job search and education assistance, career advice, and participate in mock interviews.",
      Image: AIMentorshipImage,
    },
    {
      title: "Apply For Jobs",
      text: "Utilize your FSU profile to apply for jobs, providing employers with a comprehensive understanding of your skills and strengths.",
      Image: ApplyJobsImage,
    },
    {
      title: "Invite Friends",
      text: "Collaborate with friends by playing LearnU and JobU games, fostering mutual career advancement and growth.",
      Image: InviteFriendsImage,
    },
    {
      title: "Teach 'n' Learn",
      text: "Become an 'Edfluencer' educator-creator, or learner in EducateU. Share your expertise through teaching, and expand your knowledge by exploring new skills.",
      Image: TeachLearnImage,
    },
  ]
  return (
    <Box
      sx={{
        marginTop: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          borderRadius: '16px',
          boxShadow: 5,
          overflow: 'hidden',
          width: '95%',
          maxWidth: '100%',
          position: 'relative',
          height: {xs:'3150px',sm:'3200px', md:'3000px'},
        }}
      >
        <img
          src={image2}
          alt="Description"
          style={{ 
            width: '100%', 
            height: '100%',
            objectFit: 'cover',
          }}
        />
        {/* Dark Overlay with Blur */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            borderRadius: '16px',
            backdropFilter: 'blur(5px)',
          }}
        />

        {/* Centered Text */}
        <Typography
          variant="h3"
          align="center"
          sx={{
            position: 'absolute',
            top: '30px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: '#52D5F2',
            fontWeight: 'bold',
            zIndex: 2,
            width:'100%',
            fontSize:{xs:'30px',sm:'40px', md:'50px'}
          }}
        >
          Your Personalized, Social Education, Anytime, Anywhere
        </Typography>
        <Typography
          variant="h5"
          align="center"
          sx={{
            position: 'absolute',
            top: {xs:'200px', sm:'170px'},
            left: '50%',
            transform: 'translateX(-50%)',
            color: 'white',
            zIndex: 2,
            width:'100%',
            fontSize:{xs:'15px',sm:'20px', md:'25px'}
          }}
        >
          <i>Transform Your Learning Experience with FSU!</i>
        </Typography>

        <Typography
          variant="h5"
          align="center"
          sx={{
            position: 'absolute',
            top: {xs:'270px', sm:'240px'},
            left: '50%',
            transform: 'translateX(-50%)',
            color: 'white',
            zIndex: 2,
            width:'100%',
            fontSize:{xs:'15px',sm:'20px', md:'25px'}
          }}
        >
          FSU is a new AI-powered education system that provides personalized learning tailored to your pace and preferences
        </Typography>

        {/* Horizontal Timeline Section */}
        <Box
          sx={{
            position: 'absolute',
            top: '400px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '70%',
            zIndex: 2,
          }}
        >
          <Timeline
            activeStep={activeStep}
            handleStepClick={handleStepClick}
            timelineContent={timelineContent}
            totalSteps={totalSteps}
          />
        </Box>

        {/* Final Heading */}
        <Typography
          variant="h4"
          sx={{
            position: 'absolute',
            top: {xs:'1100px',sm:'950px'},
            left: '50%',
            transform: 'translateX(-50%)',
            fontWeight: 700,
            fontSize: {xs:'35px',sm:'45px'},
            lineHeight: {xs:'40px',sm:'80px'},
            letterSpacing: '0.2em',
            textAlign: 'center',
            color: 'white',
            width:"100%",
          }}
        >
          Learn How it Works
        </Typography>
        {/* How It Works Section */}
        <Box
          sx={{
            position: 'absolute',
            top: {xs:'1240px',sm:'1140px'},
            left: '50%',
            transform: 'translateX(-50%)',
            width: {xs:'100%',sm:'80%',md:'60%'},
            zIndex: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: "40px",
          }}
        >
          {howItWorksContent.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: {xs:'column',sm:'row'},
                backgroundColor: '#232527',
                borderRadius: '20px',
                boxShadow: '0px 4px 20px 0px #FFFFFF80',
                padding:'2px',
                alignItems: 'center',
                overflow:'hidden',
                width:'100%',
              }}
            >
              <Box
                component="img"
                src={item.Image}
                alt={item.title}
                sx={{
                  width: {xs:`${index===2?'50%':'100%'}`,sm:'250px'},
                  height: {xs:'150px',sm:'250px'},
                  marginRight: {xs:0,sm:3},
                  objectFit: 'cover'
                }}
              />
              <Box
                sx={{
                  flex: 1,
                  textAlign: 'left',
                  padding:{xs:'10px',sm:'0px'}
                }}
              >
                <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold', fontSize:{xs:'18px',sm:'24px'} }}>
                  {item.title}
                </Typography>
                <Typography variant="body1" sx={{ color: 'white', marginTop: 1,fontSize:{xs:'12px',sm:'16px'} }}>
                  {item.text}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CandidateJourney;
