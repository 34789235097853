import { Box, Typography } from '@mui/material';
import React from 'react';
import { CustomButton } from "../assets/customButton";

const Timeline = ({ activeStep, handleStepClick, timelineContent, totalSteps }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        zIndex: 2,
        display: { xs: 'flex', sm: 'block' }, // stack vertically on mobile
        flexDirection: { xs: 'column', sm: 'row' }, // stack vertically on mobile
      }}
    >
      {/* Timeline Indicator */}
      <Box
        sx={{
          width: {xs:'10px',sm:'100%'},
          height: {xs:'600px',sm:'10px'},
          backgroundColor: 'black',
          marginTop: {xs:'0px',sm:'250px'},
          position: 'relative',
        }}
      >
        <Box
              sx={{
                height: {xs:`${((activeStep - 1) / (totalSteps - 1)) * 100}%`,sm:'10px'},
                width: {xs:'10px', sm:`${((activeStep - 1) / (totalSteps - 1)) * 100}%`},
                backgroundColor: '#52D5F2',
                transition: 'all 0.5s ease',
                position: 'absolute',
                left: 0,
              }}
            />
        {/* Timeline Circles */}
        {[1, 2, 3, 4].map((step) => (
          <Box
            key={step}
            onClick={() => handleStepClick(step)}
            sx={{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: activeStep >= step ? 'white' : '#999999',
              cursor: 'pointer',
              transition: 'background-color 0.5s ease',
              position: 'absolute',
              top: {xs:`${((step - 1) / (totalSteps - 1)) * 100}%`, sm:'-5px'}, 
              left: {xs:'5px', sm:`${((step - 1) / (totalSteps - 1)) * 100}%`},
              transform: 'translateX(-50%)',
            }}
          />
        ))}
      </Box>

      {/* Timeline Content */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' }, 
          justifyContent: {xs:'center', sm:'space-between'},
          marginTop: 6,
          position: 'relative',
        }}
      >
        {timelineContent.map((content, index) => (
          <Box
            key={index}
            sx={{
              width: {xs:'200px',sm:'200px',md:'300px'},
              backgroundColor: '#010F1A',
              borderRadius: '10px',
              padding: '15px',
              boxShadow: 3,
              textAlign: 'center',
              position:'absolute',
              left:{xs:'130px',sm: `${(index / (totalSteps - 1)) * 100}%`},
              transform: 'translateX(-50%)',
              top: {xs:`${-660+index*170}px`, sm:content.position === 'above' ? '-280px' : '0px'}, // Adjust vertical positioning
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'translateX(-50%) scale(1.05)',
                boxShadow: 6,
              },
            }}
          >
            <Typography variant="h6" sx={{ color: 'white', fontSize:{xs:'15px',sm:'15px', md:'20px'} }}>
              {content.title}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <CustomButton>
                {content.buttonText}
              </CustomButton>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Timeline;
