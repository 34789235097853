import { Box, Typography } from '@mui/material';
import React from 'react';
import image3 from '../../images/image3.png';
import { CustomButton } from "../assets/customButton";

const Features = () => {
  return (
    <Box
      sx={{
        marginTop: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          borderRadius: '16px',
          boxShadow: 5,
          overflow: 'hidden',
          width: '95%',
          height: "1450px",
          maxWidth: '100%',
          position: 'relative',
        }}
      >
        <img
          src={image3}
          alt="Description"
          style={{ 
            width: '100%', 
            height: '100%',
            objectFit: 'cover',
          }}
        />
        {/* Dark Overlay with Blur */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '16px',
            backdropFilter: 'blur(5px)',
          }}
        />
        {/* Centered Text */}
        <Typography
          variant="h3"
          align="center"
          sx={{
            position: 'absolute',
            top: '30px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: '#52D5F2',
            fontWeight: 'bold',
            zIndex: 2,
            width:'100%',
            fontSize:{xs:'28px',sm:'48px'}
          }}
        >
          FSU Is A New Paradigm For Team Building
        </Typography>
        <Typography
          variant="h5"
          align="center"
          sx={{
            position: 'absolute',
            top: '170px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: 'white',
            zIndex: 2,
            width:'90%',
            fontSize:{xs:'18px',sm:'24px'}
          }}
        >
          An open-world 3D campus experience and candidate sourcing marketplace, Future State University (FSU) shines new light into the candidate - employer matching process.
        </Typography>

        <Typography
          variant="h4"
          align="center"
          sx={{
            position: 'absolute',
            top: {xs:'370px',sm:'300px'},
            left: '50%',
            transform: 'translateX(-50%)',
            fontWeight: 'bold',
            color: '#52D5F2',
            zIndex: 2,
            width:'100%',
            fontSize:{xs:'20px',sm:'28px'}
          }}
        >
          Features And Benefits
        </Typography>

        {/* Branched Chart */}
        <Box
          sx={{
            position: 'absolute',
            top: '400px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '90%',
            zIndex: 2,
          }}
        >
          {/* Center Vertical Line */}
          <Box sx={{ position: 'absolute', top: 0, left: '50%', width: '2px', height: '94%', backgroundColor: '#52D5F2', transform: 'translateX(-50%)' }} />

          {/* Features */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '30px' }}>
            {[
              { name: "Informed Hiring", side: "left" },
              { name: "Gamified Assessments", side: "right" },
              { name: "Social Networking", side: "left" },
              { name: "Team Meetings", side: "right" },
              { name: "RecruitU", side: "left" },
              { name: "3D Training", side: "right" },
              { name: "DE&I (Diversity, Equity & Inclusion)", side: "left" },
            ].map((feature, index) => (
              <Box key={index} sx={{ display: 'flex', justifyContent: feature.side === 'left' ? 'flex-end' : 'flex-start', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: feature.side === 'left' ? 'row' : 'row-reverse', width: '50%' }}>
                  <Box sx={{ width: '30px', height: '2px', backgroundColor: '#52D5F2' }} />
                  <Box
                    sx={{
                      backgroundColor: '#010F1A',
                      borderRadius: '10px',
                      padding: '15px',
                      boxShadow: 3,
                      position: 'relative',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: 6,
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <Typography variant="h6" sx={{ color: 'white', fontSize:{xs:'10px',sm:'20px'} }}>
                      {feature.name}
                    </Typography>
                    <CustomButton>Learn More</CustomButton>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Features;
