import { Box, Typography } from '@mui/material'
import React from 'react'
import Mission1 from "../../images/mission1.png";
import Mission2 from "../../images/mission2.png";
import Mission3 from "../../images/mission3.png";
import { motion, useInView } from 'framer-motion';

import { useRef } from 'react';


export default function Mission() {
const ref = useRef(null);
const isInView = useInView(ref);

  return (

    <motion.div
    ref={ref}
    initial={{ opacity: 0 }}
    animate={{ opacity: isInView ? 1 : 0 }}
    transition={{ duration: 0.5 }}
  >
    <Box
      sx={{
        marginTop: 2,
        marginBottom:10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:"center",
        position: 'relative',
        width: '100%',
        padding: {xs:'5px',sm:'20px'},
      }}
    >
        
      <Box
        sx={{
          position: 'relative',
          width: {xs:'100%',sm:'70%'},
        }}
      >
        <Box
          component="img"
          src={Mission1}
          alt="Mission 1"
          sx={{
            width: '100%',
            height: '300px', // Reduced height
            objectFit: 'cover', // Ensures image covers the area without distortion
            borderRadius: '15px',
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '-150px', // Adjusted to allow for more height
            left: {xs:'0px',sm:'20px'},
            width: {xs:'50%', sm:'40%'}, // Reduced width
            height:{xs:'300px', sm:'100%'},
            backgroundColor: 'rgba(82, 213, 242, 0.7)', // Changed color to #52D5F2 with some opacity
            backdropFilter: 'blur(8px)',
            borderRadius: '15px',
            padding: {xs:'10px',sm:'20px'},
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ color: '#000', fontWeight:"Medium", fontSize:{xs:'15px', sm:'1rem'} }}>
            Who we are:
          </Typography>
          <Typography variant="body2" sx={{ color: '#000', fontWeight:"Medium", fontSize:{xs:'10px', sm:'10px', md:'14px'} }}>
            Our Future State University team brings together a dynamic blend of expertise in HR-tech, psychology, engineering, video game development, and SaaS product building. With a deep commitment to user-focused design, we are delivering a unique recruiting tool that empowers candidates and HR professionals to win in the future of work.
          </Typography>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '-160px',
            right: '-20px',
            width: '60%', // Width for the new text box
            padding: '20px',
          }}
        >
          <Typography variant="h6" sx={{ color: '#fff', textShadow: '1px 1px 2px rgba(0,0,0,0.6)', fontSize:{xs:'10px', sm:'14px', md:'20px'} }}>
            FSU is the world's first MMO professional social network and careers platform. In-game interactions lead to career advancement in the real-world!
          </Typography>
        </Box>
      </Box>
        
      <Box
        sx={{
          width: {xs:'100%',sm:'100%', md:'60%'},
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark color with opacity
          backdropFilter: 'blur(5px)',
          borderRadius: '15px',
          padding: '40px',
          display: 'flex',
          flexDirection:{xs:'column',sm:'column', md:'row'},
          alignItems: 'center',
          justifyContent: 'space-between',
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.5)',
          marginTop:"220px",
          gap:{xs:'20px',sm:'20px', md:'0px'},
        }}
      >
        <Box
          component="img"
          src={Mission2}
          alt="Mission 2"
          sx={{
            width: {xs:'80%',sm:'60%', md:'45%'},
            height: 'auto',
            borderRadius: '10px',

          }}
        />
        <Box
          sx={{
            width: {xs:'100%',sm:'100%', md:'50%'},

          }}
        >
          <Typography variant="h5" gutterBottom sx={{ color: '#fff', fontWeight: "bold", fontSize:'1rem' }}>
            Our Mission:
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', width:'100%',fontSize:{xs:'10px', sm:'15px'} }}>
            At FSU, our mission is to empower job seekers, providing them with the opportunity to enrich themselves and discover the perfect career, company, and team matches. We achieve this through meticulously tracked learning and assessment environments, supported by cutting-edge AI and search tools designed to enhance immersion and engagement.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: {xs:'100%',sm:'80%'},
          maxWidth: '1000px',
          padding: '20px',
          marginTop:"100px",
          alignItems: 'center', // This centers the content horizontally
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ color: '#fff', fontWeight: "bold", fontSize:{xs:'24px',sm:'34px'} }}>
          Our Vision:
        </Typography>
        <Typography variant="h6" sx={{ color: '#fff', fontSize:{xs:'15px',sm:'24px'} }}>
          FSU envisions a future where individuals, whether seeking lifelong learning, career upgrades, or those who didn't pursue traditional higher education, can access higher education and discover career growth within our revolutionary 3D university platform. We strive to be the guiding force, offering a unique and immersive learning experience within Future State University.
        </Typography>
      </Box>

      <Box
        sx={{
          width: {xs:'100%',sm:'100%', md:'60%'},
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark color with opacity
          backdropFilter: 'blur(5px)',
          borderRadius: '15px',
          padding: '40px',
          display: 'flex',
          flexDirection: {xs:'column',sm:'column', md:'row'},
          alignItems: 'center',
          justifyContent: 'space-between',
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.5)',
          marginTop:"100px",
          gap:{xs:'20px',sm:'20px', md:'0px'},
        }}
      >
    
        <Box
          sx={{
            width: {xs:'100%',sm:'100%', md:'50%'},
          }}
        >
          <Typography variant="h5" gutterBottom sx={{ color: '#fff', fontWeight: "bold", fontSize:'1rem' }}>
            Why 3D:
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', fontSize:{xs:'10px', sm:'15px'} }}>
          Neuroaesthetics has proven that sensory-rich environments foster creative thinking, helping us to learn faster and retain more information. Many schools, workplaces, and public spaces are being reimagined and redesigned, thanks to advancements in neuroaesthetics.
          </Typography>
        </Box>
        <Box
          component="img"
          src={Mission3}
          alt="Mission 2"
          sx={{
            width: {xs:'80%',sm:'60%', md:'45%'},
            height: 'auto',
            borderRadius: '10px',

          }}
        />
      </Box>
     
    </Box>
    </motion.div>
  )
}