import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Navigation from './components/Navigation';
import Home from './components/Home';
import LandingPage from './components/LandingPage';
import ProductCandidate from './components/ProductCandidate';
import ProductCompany from './components/ProductCompany';
import AboutContactUs from './components/AboutContactUs';
import AboutMission from './components/AboutMission';
import AboutTeam from './components/AboutTeam';

function App() {
  const [showUnity, setShowUnity] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div id="main" style={{
        backgroundImage: 'linear-gradient(to bottom, #0A0A0A, #222222', // Black gradient
        minHeight: '100vh',
        padding:'20px'
      }}>
        <Router>
        <RouteManager setShowUnity={setShowUnity} />
        {!showUnity && <Navigation />}
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<LandingPage showUnity={showUnity} setShowUnity={setShowUnity}/>} />
            <Route path="/product/candidate" element={<ProductCandidate/>}/>
            <Route path="/product/company" element={<ProductCompany/>}/>
            <Route path="/about/contact" element={<AboutContactUs/>}/>
            <Route path="/about/mission" element={<AboutMission/>}/>
            <Route path="/about/team" element={<AboutTeam/>}/>
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

function RouteManager({ setShowUnity }) {
  const location = useLocation();

  useEffect(() => {
    // Always set showUnity to false
    setShowUnity(false);
  }, [location, setShowUnity]);

  return null; // This component is only for managing routes, no UI needed
}


export default App;